import Web3 from 'web3'
import BigNumber from "bignumber.js";


const formatAmount = (inputAmount) => {

    let data = {
        symbol: tokenSettings.symbol,
        full: '0',
        natural: '0',
        naturalFormatted: '0',
        decimal: '000',
        inWei: '0',
        isNull: true,
    };

    let amountFloat = new BigNumber(inputAmount)

    if (amountFloat.gt(0)) {

        const BN = Web3.utils.BN;

        const balanceWeiString = inputAmount.toString()
        const balanceWeiBN = new BN(balanceWeiString)

        const decimalsBN = new BN(tokenSettings.decimals)
        const divisor = new BN(10).pow(decimalsBN)

        const natural = balanceWeiBN.div(divisor).toString()
        let decimal = zeroPad(balanceWeiBN.mod(divisor).toString(), tokenSettings.decimals);
        while (decimal.charAt(decimal.length - 1) === "0") {
            decimal = decimal.slice(0, -1)
        }
        while (decimal.length < 3) {
            decimal = decimal + '0';
        }
        const naturalFormatted = new Intl.NumberFormat().format(balanceWeiBN.div(divisor)).toString();

        data = {
            symbol: tokenSettings.symbol,
            full: natural + '.' + decimal,
            decimal,
            natural,
            naturalFormatted,
            inWei: balanceWeiBN,
            isNull: false,
        };
    }

    return data;

}

function zeroPad(num, places)
{
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}


const tokenSettings = {
    decimals: 18,
    symbol: 'PHCR',
}


const addBN = (num1, num2) => {
    let a = new BigNumber(num1)
    let b = new BigNumber(num2)
    return a.plus(b);
}

const isLTE = (num1, num2) => {

    let a = new BigNumber(num1)
    let b = new BigNumber(num2)
    return a.lte(b);
}

const APRCalc = (rewardRate, totalSupply) => {
    let rr = new BigNumber(rewardRate).multipliedBy(100).multipliedBy(365).multipliedBy(24).multipliedBy(60).multipliedBy(60);
    let ts = new BigNumber(totalSupply);
    let minCheck = new BigNumber(1000000).multipliedBy(new BigNumber(10).pow(18))
    if (!ts.gt(minCheck)) {
        ts = minCheck;

    }
    // alert(rr.dividedBy(ts).toString());
    return rr.dividedBy(ts).toString();
}


const
    wait = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms))


export default { wait, formatAmount, addBN, isLTE, APRCalc }