<template>
  <div>
    <div v-if="!isLoading">
      <PortalAccount :account="account" :network="network"/>
      <PortalBalance/>
      <PortalStakeV2/>
      <PortalStake/>
      <div v-if="!hasContract" class="text-center text-gray-100 italic">
        This account does not belong to a $PHCR lock contract
      </div>
      <div v-if="hasContract">
        <AppDivider>Vesting Contract</AppDivider>
        <PortalLockContract v-if="lockInfo.mbl1.hasLock" @refresh="setLockContract" mbl="mbl1" :contract="lockInfo.mbl1"/>
        <PortalLockContract v-if="lockInfo.mbl2.hasLock" @refresh="setLockContract" mbl="mbl2" :contract="lockInfo.mbl2"/>
        <PortalLockContract v-if="lockInfo.mbl3.hasLock" @refresh="setLockContract" mbl="mbl3" :contract="lockInfo.mbl3"/>
        <PortalLockContract v-if="lockInfo.mbl4.hasLock" @refresh="setLockContract" mbl="mbl4" :contract="lockInfo.mbl4"/>
      </div>
    </div>
    <AppLoader v-if="isLoading">Loading Portal</AppLoader>
  </div>
</template>
<script setup>
import { inject, onMounted, ref } from "vue";
import AppLoader from "./AppLoader";
import AppDivider from "./AppDivider";
import lockFunctions from "../utils/crypto/multiBeneficiaryLock";
import connection from "../utils/crypto/connection";
import PortalBalance from "./PortalBalance";
import PortalStake from "./PortalStake";
import PortalAccount from "./PortalAccount";
import PortalLockContract from "./PortalLockContract";
import PortalStakeV2 from "@/components/PortalStakeV2.vue";

const emitter = require('tiny-emitter/instance');

const isLoading = ref(true)
const account = ref(false)
const network = ref('')
const lockInfo = ref(null)
const hasContract = ref(false)
const state = inject('state')
const overlayLoading = inject('overlayLoading')

async function setAccount()
{
  let acc = await connection.getAccount();
  if (!acc) {
    state.value = 'connect'
  }
  account.value = acc.short
  network.value = connection.getNetwork()
}


async function setLockContract()
{
  let getLockInfo = await lockFunctions.getLockInfo();
  lockInfo.value = getLockInfo
  if (!getLockInfo['hasOne']) {
    hasContract.value = false
    return
  }
  hasContract.value = true
}

async function metaMaskListeners()
{
  window.ethereum.on('accountsChanged', async () => {
    await reload()
  });
  window.ethereum.on('chainChanged', async () => {
    await reload()
  });

}

async function reload()
{
  isLoading.value = true
  await setAccount();
  await setLockContract()
  isLoading.value = false
  overlayLoading.value = false;
}


onMounted(async () => {
  //Is metamask connected?

  await setAccount();
  await setLockContract();
  await metaMaskListeners()

  isLoading.value = false
  emitter.on('reload', async function(){
    await reload();
  });
})
</script>