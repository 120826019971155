import Contract from './contract'
import addresses from './settings/addresses'
import connection from './connection'
import helpers from "./helpers";


const getAcc = async () => {
    let connectedAccount = await connection.getAccount();
    if (connectedAccount) {
        return connectedAccount.full
    }

    return false;
}


const getLockInfo = async () => {

    let address = await getAcc()
    let mbls = ['mbl1', 'mbl2', 'mbl3', 'mbl4', 'mbl5']

    let lockInfo = {};
    lockInfo.hasOne = false;
    for (let mbl of mbls) {
        lockInfo[mbl] = {}
        lockInfo[mbl].hasLock = false
        try {
            let contract = await returnContract(mbl)
            let locked = helpers.formatAmount(await contract.locked([address]))
            let released = helpers.formatAmount(await contract.released([address]))
            let amountCanRelease = helpers.formatAmount(await contract.amountCanRelease([address]))

            if (!locked.isNull || !released.isNull || !amountCanRelease.isNull) {
                lockInfo[mbl].hasLock = true
                lockInfo['hasOne'] = true
            }

            lockInfo[mbl]['locked'] = locked
            lockInfo[mbl]['released'] = released
            lockInfo[mbl]['amountCanRelease'] = amountCanRelease
        } catch (e) {
            //pass through
        }
    }
    return lockInfo;
}

const returnContract = ($key) => {
    let account = addresses.getAddress(connection.getNetwork(), $key)
    return new Contract('multiBeneficiaryLock', account)
}

const release = async ($key) => {
    let lock = await returnContract($key)
    try {
        let releaseTx = await lock.release()
        await releaseTx;
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export default {
    getAcc,
    getLockInfo,
    release,
}