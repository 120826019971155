import { serializeError } from 'eth-rpc-errors'

const parseError = (errorBag) => {
    let data = {
        code: 0,
        message: 'Unexpected error',
    }
    // const fallbackError = { code: 4999, message: 'Unexpected error' };
    let errore = errorBag.toString()
    if (errore.indexOf('Internal JSON-RPC error.') > -1) {
        errore = errore.replace('\n', '').replace("Error: ", '').replace('Internal JSON-RPC error.', '')
        errore = JSON.parse(errore)
        data.code = errore.code;
        data.message = errore.message;
    } else {
        const fallbackError = { code: 4999, message: 'Unknown Error' }
        let serializedError = serializeError(errorBag, fallbackError)
        data.code = serializedError.code;
        data.message = serializedError.message;
    }

    return data;

}

export default parseError;