<template>
  <div v-if="!hideStake">
    <div v-if="loadingDetails">
      <AppLoader>Staking</AppLoader>
    </div>
    <div v-if="!loadingDetails && didLoad">
      <AppDivider>PHCR Staking (Expired)</AppDivider>
      <div class="flex items-center justify-center pc_bg_text mt-5 mb-3">
        <small class="text-sm mr-3">Current APR </small>
        <h3 class="text-center pc_bg_text text-4xl">{{ apr }}%</h3>
      </div>
      <div class="flex justify-center items-center p-4" v-if="!showStaking">
        <button class="btn" @click="showStaking = !showStaking">Stake PHCR</button>
      </div>
      <div v-if="showStaking">
        <div class=" p-4 flex justify-between">
          <div class="w-80 flex">
            <input type="text" name="amount" id="amount" v-model="amount" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Amount">
            <button class="btn ml-2" @click="stakeTokens">Stake</button>
          </div>
          <div>
            <button class="btn ml-2" @click="stakeAllTokens">Stake All</button>
            <button class="btn-secondary ml-2" @click="showStaking = !showStaking">X</button>
          </div>
        </div>
      </div>
      <AppCard>
        <div class="relative">
          <div class="flex justify-between items-center my-1 row">
            <div class="flex items-center">
              Staked
            </div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensStaked.naturalFormatted }}</span>.<small>{{ tokensStaked.decimal }}</small>
              <button @click="unStake" v-if="!tokensStaked.isNull" class="btn-secondary-sm ml-2">Withdraw</button>
            </div>
          </div>
          <div class="flex justify-between items-center my-1 row">
            <div>Earned</div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensEarned.naturalFormatted }}</span>.<small>{{ tokensEarned.decimal }}</small>
              <button @click="cashOutRewardsOnly" v-if="!tokensEarned.isNull" class="btn-secondary-sm ml-2">Get Reward</button>
            </div>
          </div>
          <div class="flex justify-between items-center my-1 row">
            <div>Total</div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensStakedAndEarned.naturalFormatted }}</span>.<small>{{ tokensStakedAndEarned.decimal }}</small>
              <button @click="cashOutAll" v-if="!tokensStakedAndEarned.isNull" class="btn-secondary-sm ml-2">Get All</button>
            </div>
          </div>
          <div class="absolute -right-4 -bottom-5 text-xs text-gray-100"></div>
        </div>
      </AppCard>
    </div>
  </div>
</template>
<script setup>
import AppCard from "./AppCard";
import AppDivider from "./AppDivider";
import { inject, onMounted, ref } from "vue";
import AppLoader from "./AppLoader";
import stakingFunctions from "../utils/crypto/stakingFunctions";
import Web3 from "web3";
import helpers from "@/utils/crypto/helpers";


const emitter = require('tiny-emitter/instance');

// const emitter = require('tiny-emitter/instance');
const hideStake = ref(false)
const loadingDetails = ref(true)
const didLoad = ref(false)
const showStaking = ref(false)
const hasStaked = ref(false)
const hasEarned = ref(false)
const tokensStaked = ref({})
const tokensEarned = ref({})
const tokensStakedAndEarned = ref({})
const symbol = ref('PHCR')
const balance = inject('balance')
const amount = ref('');
const apr = ref('0');
const overlayLoading = inject('overlayLoading')


const stakeTokens = async () => {

  let amt = amount.value;
  amt = amt.replaceAll(',', '');
  let weiValue;
  try {
    weiValue = Web3.utils.toWei(amt);
  } catch (e) {
    emitter.emit('fire-alert', 'Invalid input. Please enter an amount greater than zero', 'error')
    return false;
  }
  if (weiValue <= 0) {
    emitter.emit('fire-alert', 'Please enter an amount greater than zero', 'error')
    return false;
  }

  if (!helpers.isLTE(weiValue, balance.value)) {
    emitter.emit('fire-alert', 'The amount exceeds your balance', 'error')
    return false;
  }

  overlayLoading.value = true;
  try {
    await stakingFunctions.stakeTokens(weiValue)
    emitter.emit('fire-alert', 'Staking successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (error) {
    console.log(error);
    emitter.emit('fire-alert', 'Staking incomplete', 'error', 'Error:' + error)
    overlayLoading.value = false;
  }

}

const stakeAllTokens = async () => {
  amount.value = '';
  overlayLoading.value = true;
  if (balance.value <= 0) {
    emitter.emit('fire-alert', 'You are not holding any tokens', 'error')
    overlayLoading.value = false;
    return
  }

  try {
    await stakingFunctions.stakeTokens(balance.value)
    emitter.emit('fire-alert', 'Staking successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (error) {
    console.log(error);
    emitter.emit('fire-alert', 'Staking All incomplete', 'error', 'Error:' + error)
    overlayLoading.value = false;
  }
}

const getStaked = async () => {
  let staked = await stakingFunctions.getTokensStaked();
  if (!staked.isNull) {
    hasStaked.value = true;
  }

  tokensStaked.value = staked;

  // tokensStaked.value = staked;
}

const getStakedAndEarned = async () => {
  let earned = await stakingFunctions.getEarned();
  let both = await stakingFunctions.getBalanceAndRewards();
  if (!both.isNull) {
    hasEarned.value = true;
  }

  tokensEarned.value = earned
  tokensStakedAndEarned.value = both;
}

const init = async () => {
  loadingDetails.value = true;
  try {
    await getStaked();
    await getStakedAndEarned();
    didLoad.value = true
  } catch (e) {
    // emitter.emit('fire-alert', 'Cannot load staking', 'error', e.message)
  }
  loadingDetails.value = false;
}

const cashOutRewardsOnly = async () => {

  overlayLoading.value = true;

  try {
    await stakingFunctions.cashOutRewardsOnly();
    emitter.emit('fire-alert', 'Withdraw successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (error) {
    emitter.emit('fire-alert', 'Withdraw incomplete', 'error', 'Error: ' + error.message)
    overlayLoading.value = false;
  }
}

const unStake = async () => {

  overlayLoading.value = true;

  try {
    await stakingFunctions.unStake();
    emitter.emit('fire-alert', 'Withdraw successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (error) {
    emitter.emit('fire-alert', 'Withdraw incomplete', 'error', 'Error: ' + error.message)
    overlayLoading.value = false;
  }
}

const cashOutAll = async () => {

  overlayLoading.value = true;

  try {
    await stakingFunctions.cashOutAll();
    emitter.emit('fire-alert', 'Withdraw successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (error) {
    emitter.emit('fire-alert', 'Withdraw incomplete', 'error', 'Error: ' + error.message)
    overlayLoading.value = false;
  }
}

const getApr = async () => {
  let aprValue = await stakingFunctions.getApr();
  // let aprformatted = helpers.formatAmount(aprValue);
  // apr.value = aprformatted.full;
  apr.value = parseFloat(aprValue).toFixed();
}

onMounted(async () => {
  await init()
  if (hasStaked.value || hasEarned.value) {
    setInterval(function(){
      getStakedAndEarned()
    }, 5000);

    await getApr();
  } else {
    hideStake.value = true;
  }

});
</script>