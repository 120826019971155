<template>
  <div class='flex justify-between items-center'>
    <div>
      <div class="sm:mx-auto sm:w-full sm:max-w-md py-12">
        <img src="../assets/img/logo_full.png" class="mx-auto h-12 w-auto" alt="Logo"/>
      </div>
    </div>
    <div>
      <h4 class='text-primary font-bold relative text-center '>
        {{ account }} <br/>
        <small class="font-normal text-gray-200 text-xs m-0 p-0 leading-3">Network:
          <span class="text-gray-300 uppercase">{{ network }}</span></small>
      </h4>
    </div>
  </div>
</template>
<script>
// import { ref } from "vue";


export default {

  props: {
    account: {
      require: true
    },
    network: {}
  },
  setup()
  {

  }
}
</script>