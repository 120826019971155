<template>
  <div class="bg-white shadow sm:rounded-lg mb-4">
    <div class="px-4 py-5 sm:p-6">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  setup()
  {

  }
}
</script>