<template>
  <div>
    <div v-if="loadingDetails">
      <AppDivider>PHCR Member Staking</AppDivider>
      <AppLoader>Staking</AppLoader>
    </div>
    <div v-if="!loadingDetails && didLoad">
      <AppDivider>PHCR Member Staking</AppDivider>
      <div class="flex items-center justify-center pc_bg_text mt-5 mb-3">
        <small class="text-sm mr-3">Current APR </small>
        <h3 class="text-center pc_bg_text text-4xl">{{ apr }}%</h3>
      </div>
      <div v-if="!hasNFT" class="text-purple-500 text-center mb-4">
        <button class="btn-disabled">Stake PHCR</button>
      </div>
      <div v-if="hasNFT">
        <div class="flex justify-center items-center p-4" v-if="!showStaking">
          <button class="btn" @click="showStaking = !showStaking">Stake PHCR</button>
        </div>
        <div v-if="showStaking">
          <div class=" p-4 flex justify-between">
            <div class="w-80 flex">
              <input type="text" name="amount" id="amount" v-model="amount" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="Amount">
              <button class="btn ml-2" @click="stakeTokens">Stake</button>
            </div>
            <div>
              <button class="btn ml-2" @click="stakeAllTokens">Stake All</button>
              <button class="btn-secondary ml-2" @click="showStaking = !showStaking">X</button>
            </div>
          </div>
        </div>
      </div>
      <AppCard>
        <div class="relative">
          <div class="flex justify-between items-center my-1 row">
            <div class="flex items-center">
              Staked
            </div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensStaked.naturalFormatted }}</span>.<small>{{ tokensStaked.decimal }}</small>
            </div>
          </div>
          <div class="flex justify-between items-center my-1 row">
            <div>Earned</div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensEarned.naturalFormatted }}</span>.<small>{{ tokensEarned.decimal }}</small>
            </div>
          </div>
          <div class="flex justify-between items-center my-1 row">
            <div>Total</div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ tokensStakedAndEarned.naturalFormatted }}</span>.<small>{{ tokensStakedAndEarned.decimal }}</small>
            </div>
          </div>
          <div class="flex justify-end">
            <button @click="cashOutAll" v-if="!tokensStakedAndEarned.isNull" class="btn-secondary-sm ml-2">Withdraw all</button>
            <div v-if="false">
              <button @click="incTime(1)" class="btn-secondary-sm">1 sec</button>
              <button @click="incTime(60)" class="btn-secondary-sm">60 sec</button>
              <button @click="incTime(3600)" class="btn-secondary-sm">1 hour</button>
              <button @click="incTime(86400)" class="btn-secondary-sm">24 hours</button>
            </div>
          </div>
        </div>
      </AppCard>
    </div>
  </div>
</template>
<script setup>
import AppCard from "./AppCard";
import AppDivider from "./AppDivider";
import { inject, onMounted, ref } from "vue";
import AppLoader from "./AppLoader";
import Web3 from "web3";
import helpers from "@/utils/crypto/helpers";
import stakingFunctionsV2 from "../utils/crypto/stakingFunctionsV2.js";
import parseError from "@/utils/parseError.js";

const isLive = true;

const emitter = require('tiny-emitter/instance');

// const emitter = require('tiny-emitter/instance');
const loadingDetails = ref(true)
const didLoad = ref(false)
const showStaking = ref(false)
const hasStaked = ref(false)
const hasEarned = ref(false)
const hasNFT = ref(false)
const tokensStaked = ref({})
const tokensEarned = ref({})
const tokensStakedAndEarned = ref({})
const symbol = ref('PHCR')
const balance = inject('balance')
const amount = ref('');
const apr = ref(0);
const overlayLoading = inject('overlayLoading')


const stakeTokens = async () => {

  let amt = amount.value;
  amt = amt.replaceAll(',', '');
  let weiValue;
  try {
    weiValue = Web3.utils.toWei(amt);
  } catch (e) {
    emitter.emit('fire-alert', 'Invalid input. Please enter an amount greater than zero', 'error')
    return false;
  }
  if (weiValue <= 0) {
    emitter.emit('fire-alert', 'Please enter an amount greater than zero', 'error')
    return false;
  }

  if (!helpers.isLTE(weiValue, balance.value)) {
    emitter.emit('fire-alert', 'The amount exceeds your balance', 'error')
    return false;
  }

  overlayLoading.value = true;
  try {
    await stakingFunctionsV2.stakeTokens(weiValue)
    emitter.emit('fire-alert', 'Staking successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (e) {
    console.log(e);
    let error = parseError(e);
    emitter.emit('fire-alert', 'Staking incomplete', 'error', 'Error:' + error.message)
    overlayLoading.value = false;
  }

}

const stakeAllTokens = async () => {
  amount.value = '';
  overlayLoading.value = true;
  if (balance.value <= 0) {
    emitter.emit('fire-alert', 'You are not holding any tokens', 'error')
    overlayLoading.value = false;
    return
  }

  try {
    await stakingFunctionsV2.stakeTokens(balance.value)
    emitter.emit('fire-alert', 'Staking successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (e) {
    console.log(e);
    let error = parseError(e);
    emitter.emit('fire-alert', 'Staking All incomplete', 'error', 'Error:' + error.message)
    overlayLoading.value = false;
  }
}

const getStaked = async () => {
  let staked = await stakingFunctionsV2.getTokensStaked();
  if (!staked.isNull) {
    hasStaked.value = true;
  }
  tokensStaked.value = staked;

}

const getStakedAndEarned = async () => {
  let earned = await stakingFunctionsV2.getEarned();

  let both = await stakingFunctionsV2.getBalanceAndRewards();
  if (!both.isNull) {
    hasEarned.value = true;
  }

  tokensEarned.value = earned
  tokensStakedAndEarned.value = both;
}

const init = async () => {
  loadingDetails.value = true;
  try {

    await checkNft();

    await getStaked();

    await getStakedAndEarned();

    didLoad.value = true
  } catch (e) {
    console.log(e);
    let error = parseError(e);
    emitter.emit('fire-alert', 'Cannot load member staking', 'error', error.message)
  }
  loadingDetails.value = false;
}

const incTime = async (amount) => {
  await stakingFunctionsV2.incTime(amount);
}

const checkNft = async () => {
  hasNFT.value = await stakingFunctionsV2.hasNft();
}
const cashOutAll = async () => {

  overlayLoading.value = true;

  try {
    await stakingFunctionsV2.cashOutAll();
    emitter.emit('fire-alert', 'Withdraw successful', 'success')
    overlayLoading.value = false;
    await emitter.emit('reload')
  } catch (e) {
    let error = parseError(e);
    emitter.emit('fire-alert', 'Withdraw incomplete', 'error', 'Error: ' + error.message)
    overlayLoading.value = false;
  }
}

const getApr = async () => {
  try {
    let aprValue = await stakingFunctionsV2.getApr();
    apr.value = parseFloat(aprValue).toFixed(3);
  } catch (e) {
    let error = parseError(e);
    console.log(error);
  }

}

onMounted(async () => {
  if (isLive) {
    await init()

    if (hasStaked.value) {
      setInterval(function(){
        getStakedAndEarned()
      }, 5000);

    }
    await getApr();
  }


});
</script>