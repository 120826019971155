const addresses = {
    ropsten: {
        mbl1: '0x96E59FD5c4A921881AB0b66a5886f6Edc2F95071',
        mbl2: '0x004b9ff6330AdbF2146D4f06b76C1d145d57A4d0',
        mbl3: '0xd7DD2546414f169F9Fc2BD81a3b2A3E001032632',
        mbl4: '',
        erc20_pre: '0x982869453E48AA3E3D0ecCc197100051039c4B49',
        staking: '',

        erc20: '0x49DA69F8FffDc55FA9e0D9E4e17b51c836251Db8',
        // nft: '0xdb464e93334a2EA9d731E3dEfc2888877c3E6DCA',
        // treasury: '0x12142Eb3049dAAA3e8448B637ece35d63a62896f',
        // staking_v2: '0xcd0D90b6DcEe33410D185534977af0880fffDCE9',
        staking_v2: '0xbcEc94B7245eb0F0efAbe0D7C821D8b4ef2E2Bcc',
    },
    fantomTest: {
        mbl1: '',
        mbl2: '',
        mbl3: '',
        mbl4: '',
        erc20_pre: '',
        staking: '',

        erc20: '0xa63D70896717001b297c3fB921A598c7443090EE',
        // nft: '0xdb464e93334a2EA9d731E3dEfc2888877c3E6DCA',
        // treasury: '0x12142Eb3049dAAA3e8448B637ece35d63a62896f',
        // staking_v2: '0xCAEE2d3f9d85F616E2E7598247e45b1E4FFC8355',
        // staking_v2: '0xbcEc94B7245eb0F0efAbe0D7C821D8b4ef2E2Bcc',
        // staking_v2: '0x2378274eaDbAf558B6c9a7bE6E20DA025398DC7B',
        // staking_v2: '0x5D39a0d1F8b9C5cfeC5F468277621B12cDf21DB8',
        // staking_v2: '0x1B88099505E41F76d26C236348273274aa0eac15', //LIVE version
        // staking_v2: '0x7d8A1ED205Dec8F2dBd42f91cC85fD1373dC2E20', //LIVE version
        // staking_v2: '0x7D0E693f0860b2Ad0cfD69C5583aBe2F7A99e2d4', //LIVE version
        // staking_v2: '0x0a9b2037FFC6D47cc2Aff36bA1E90C4FDA44613A', //LIVE version
        staking_v2: '0x55570441170CE67015d5d2914deFf19354B3B29a', //LIVE version
    },
    goerli: {
        mbl1: '',
        mbl2: '',
        mbl3: '',
        mbl4: '',
        erc20_pre: '',
        staking: '',

        erc20: '0xF67DE1d9b3aF7F62efC07873b29b65B96d2B3FCE',
        // nft: '0xdb464e93334a2EA9d731E3dEfc2888877c3E6DCA',
        // treasury: '0x12142Eb3049dAAA3e8448B637ece35d63a62896f',
        // staking_v2: '0xCAEE2d3f9d85F616E2E7598247e45b1E4FFC8355',
        // staking_v2: '0xbcEc94B7245eb0F0efAbe0D7C821D8b4ef2E2Bcc',
        // staking_v2: '0x2378274eaDbAf558B6c9a7bE6E20DA025398DC7B',
        // staking_v2: '0x5D39a0d1F8b9C5cfeC5F468277621B12cDf21DB8',
        // staking_v2: '0x1B88099505E41F76d26C236348273274aa0eac15', //LIVE version
        // staking_v2: '0x7d8A1ED205Dec8F2dBd42f91cC85fD1373dC2E20', //LIVE version
        // staking_v2: '0x7D0E693f0860b2Ad0cfD69C5583aBe2F7A99e2d4', //LIVE version
        // staking_v2: '0x0a9b2037FFC6D47cc2Aff36bA1E90C4FDA44613A', //LIVE version
        // staking_v2: '0xa63D70896717001b297c3fB921A598c7443090EE', //LIVE version
        staking_v2: '0x1533c618EF8B8AcfB735456a4F813ceFE46acD4f', //LIVE version
    },
    rinkeby: {
        mbl1: '0xcb8a0754626863187b1F4E7B09Ee351eE1bD6CAC',
        mbl2: '0x8870E4Ff135a18d147544E20635C3464DacDF8c3',
        mbl3: '0xbd518e284f04d04ff3ee4881a464e0cd6f6449bd',
        mbl4: '',
        // erc20: '0x257052Aae0813e667a150c0b4FD950d04961E5c0',
        erc20: '0xea9f188a0187fbafab3953edfce5386b101fe5ab',
        staking: '0xc34D15F3d4a73587249F90c65d35386D21f5b495',
    },
    mainnet: {
        mbl1: '0xc32a523fa5c7ff63548da4567c6308c6dcb4f552',
        mbl2: '0xb7056c1065B53c02F6104BE771D80F98BF4f67e5',
        mbl3: '0xF3d7aa957F5Cd4e3b8947b6acb88f2B8fd9A2e88',
        mbl4: '0x6de652344fd5db5b44dcfb8e52556cc1abdbbfa8',
        mbl5: '0x094631d37fCB11fA6924d5f621146c0a94a6107c',
        erc20: '0x37E83a94c6B1Bdb816B59aC71dd02CF154d8111F',
        staking: '0x2d3d2F1540e9F316E4B91963263d4dF9d1Eb08f3',
        // staking_v2: '0x90AFfa0A6D86396a17fEcC6fEbd2c3737AE551C0',
        staking_v2: '0xb955C646A81eeA7Fffb5B5F4A3D66Da4eE285d86',
    }
}

const getAddress = (network, name) => addresses[network][name]

const $ = {
    getAddress
}

export default $