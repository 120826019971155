<template>
  <div>
    <div v-if="loadingDetails">
      <AppLoader>Loading Balance</AppLoader>
    </div>
    <div v-if="!loadingDetails">
      <div v-if="!hasNft" class="relative pb-8">
        <div class="flex justify-center items-center row text-black ">
          <div class="bg-white  sm:rounded-lg shadow-2xl text-center bg-white border border-purple-600 w-full">
            <div class="px-4 py-5 sm:p-6  gap-2">
              <div>To enjoy staking rewards, you'll need to hold a PhotoChromic identity NFT in your wallet.</div>
              <a href="https://mint.photochromic.io/" target="_blank" class="btn text-white mt-4">Mint your identity now</a>
            </div>
          </div>
        </div>
      </div>
      <div class="relative pb-8">
        <div class="flex justify-between gap-4 items-center  text-black">
          <div><span class='text-primary text-2xl mr-1'>${{ symbol }}</span>
            <span class='text-4xl'>{{ tokenBalance.naturalFormatted }}</span>.<small>{{ tokenBalance.decimal }}</small>
          </div>
          <a href="https://www.huobi.com/en-us/exchange/phcr_usdt/" target="_blank" class="btn">Buy&nbsp;PHCR</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import AppCard from "./AppCard";
import { inject, onMounted, ref } from "vue";
import AppLoader from "./AppLoader";
// import AppDivider from "./AppDivider";
import stakingFunctions from "../utils/crypto/stakingFunctions";
import stakingFunctionsV2 from "../utils/crypto/stakingFunctionsV2.js";
import parseError from "@/utils/parseError.js";


const emitter = require('tiny-emitter/instance');

// const emitter = require('tiny-emitter/instance');
const loadingDetails = ref(true)
const tokenBalance = ref({})
const symbol = ref('PHCR')
const balance = inject('balance')
const hasNft = ref(false)

const getBalance = async () => {
  try {
    let bal = await stakingFunctions.getCurrentBalance();
    tokenBalance.value = bal;
    balance.value = bal.inWei;
  } catch (e) {
    let error = parseError(e);
    emitter.emit('fire-alert', 'Cannot load PHCR balance', 'error', error.message)

  }

}

const checkNft = async () => {
  try {
    hasNft.value = await stakingFunctionsV2.hasNft()
  } catch (e) {
    console.log(e);
    // emitter.emit('fire-alert', 'Cannot load PHCR balance', 'error', e.message)
  }

}


onMounted(async () => {
  await getBalance();
  await checkNft();
  loadingDetails.value = false;

});
</script>