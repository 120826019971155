<template>
  <div>
    <div v-if="loadingDetails">
      <AppLoader>Please wait</AppLoader>
    </div>
    <div v-if="!loadingDetails">
      <AppCard>
        <div class="relative">
          <div class="flex justify-between items-center row text-gray-400">
            <div class="flex items-center">
              <LockClosedIcon class="h-4 h-4"></LockClosedIcon>
              Locked
            </div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ contract.locked.naturalFormatted }}</span>.<small>{{ contract.locked.decimal }}</small>
            </div>
          </div>
          <div class="flex justify-between items-center  row">
            <div class="flex items-center">
              <LockOpenIcon class="h-4 w-4"></LockOpenIcon>
              Unlocked
            </div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ contract.amountCanRelease.naturalFormatted }}</span>.<small>{{ contract.amountCanRelease.decimal }}</small>
            </div>
          </div>
          <div class="flex justify-between items-center  row">
            <div>Total Released</div>
            <div>
              <span class='text-primary mr-1'>${{ symbol }}</span>
              <span class='text-lg'>{{ contract.released.naturalFormatted }}</span>.<small>{{ contract.released.decimal }}</small>
            </div>
          </div>
          <div class="absolute -right-4 -bottom-5 text-xs text-gray-100">{{ mbl }}</div>
        </div>
      </AppCard>
      <div v-if="canRelease" class="flex justify-center">
        <button class="btn" @click="releaseToAccount">
          Release
          <span class='text-indigo-200 mx-1'>${{ symbol }}</span>
          <span class='text-lg'>{{ contract.amountCanRelease.naturalFormatted }}</span>.<small>{{ contract.amountCanRelease.decimal }}</small>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import AppCard from "./AppCard";
import { onMounted, ref, defineProps, defineEmits } from "vue";
import AppLoader from "./AppLoader";
import lockFunctions from "../utils/crypto/multiBeneficiaryLock";

import { LockClosedIcon, LockOpenIcon } from "@heroicons/vue/solid"


const props = defineProps({
  contract: {
    required: true,
  },
  mbl: {
    required: true,
  },
  symbol: {
    // required: true,
    default: 'PHCR'
  },
})

const emit = defineEmits(['refresh'])

const emitter = require('tiny-emitter/instance');
const canRelease = ref(false)
const loadingDetails = ref(false)


const releaseToAccount = async () => {

  loadingDetails.value = true
  const released = await lockFunctions.release(props.mbl)
  if (released) {
    emitter.emit('fire-alert', 'Your tokens have been released', 'success')
    canRelease.value = false
  } else {
    emitter.emit('fire-alert', 'Your tokens have not been released', 'error')
  }
  loadingDetails.value = false
  emit('refresh', true)
}

onMounted(() => {
  if (!props.contract.amountCanRelease.isNull) {
    canRelease.value = true;
  }


});
</script>
<style scoped>
.row {
  @apply rounded flex justify-between hover:bg-indigo-100 p-2;
}
</style>


